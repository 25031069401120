.modalCustom {
  display: flex;
  align-items: center;
  justify-content: center;
  .change-password-modal {
    background-color: white;
    border-radius: 5px;
    width: 568px;
    position: relative;
    padding: 50px 32px;
    text-align: center;
    &:focus-visible {
      outline: 0;
    }
    .close-btn {
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
    }
    .successIcon {
      svg {
        width: 151.4px;
        height: 151.4px;
      }
    }
    .title {
      font-family: GRIFTER;
      font-size: 24px;
      font-weight: 700;
      line-height: 24.48px;
      letter-spacing: 0.01em;
      text-align: center;
      margin-top: 56px;
    }
    .text {
      margin-top: 24px;
      margin-bottom: 56px;
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
    .buttons-group {
      width: 100%;
      text-align: center;
      .okButton {
        button {
          width: 70%;
          font-size: 16px;
          height: 52px;
        }
      }
      .cancelButton {
        font-family: MonoSpec;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.98px;
        letter-spacing: 0.01em;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 6px;
        margin-top: 24px;
        &:active {
          color: #ff4106;
        }
      }
    }
  }
}
