@import 'styles/scss/fontSizeByRem.scss';
.ToggleGroupButton {
  width: 100%;
  gap: toRem(12);
  display: inline-flex;
  .switchButton {
    flex: auto;
    background-color: white;
    border: 1px solid #161616 !important;
    text-transform: lowercase !important;
    border-radius: 8px !important;
    max-width: toRem(154);
    color: #161616;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    cursor: pointer;
    font-size: toRem(16);
  }
  .activeButton {
    background-color: #161616;
    color: white;
  }
}