@import 'styles/scss/variable.scss';
.ChangeEmailConfirmPage {
  text-align: center;
  min-height: 50vh;
  .ChangeEmailErrorPage {
    .title {
      font-family: GRIFTER;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .buttonGroup {
      button {
        width: 320px;
      }
    }
  }
  .ChangeEmailSuccessPage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zIndexMax;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-family: GRIFTER;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .buttonGroup {
      button {
        width: 320px;
      }
    }
  }
}