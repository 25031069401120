.InputPassword {
  position: relative;
  display: flex;
  align-items: center;
  :global(.MuiOutlinedInput-root) {
    input {
      padding-right: 50px;
    }
    //Hide password icon on edge
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
  }
  .iconPassword {
    position: absolute;
    right: 16px;
    svg {
      width: 24px;
      height: 24px;;
      fill: #666666;
    }
  }
}