@import 'styles/scss/fontSizeByRem.scss';

.NumberAndHeightUnit {
  .selectWrapper {
    height: 48px;
    margin-top: -8px;
    :global(.MuiSelect-select) {
      color: #F5490F !important;
      font-weight: 400 !important;
    }
    :global(.placeholderCustom) {
      color: #F5490F !important;
      font-size: toRem(16);
    }
  }
}