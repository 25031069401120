
.modalCustom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-modal {
  background-color: white;
  border-radius: 5px;
  width: 568px;
  position: relative;
  &:focus-visible {
    outline: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 20px;
  }

  .close-btn {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }

  .text-field-group {
    padding: 10px 32px;
  }

  .titleModal {
    padding: 48px 32px 24px 32px;
    font-weight: bold;
    font-size: 24px;
    color: #161616;
    font-family: GRIFTER;
  }

  .buttons-group {
    padding: 24px 32px;
    text-align: right;

    :global(.MuiButton-contained) {
      padding: 12px;
      border-radius: 100px;
      width: 240px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .text-field-group {
      padding: 10px 36px;
    }
    
    .buttons-group {
      padding: 24px 36px;
    }
  }
}
