.modal {
    position: relative;
    border: none;
    box-shadow: none;
    outline: none;
    .modalChangeMachine {
        min-width: 300px;
        max-width: 700px;

        border-radius: 5px;
        background-color: white;
        padding: 40px 32px;
        border: none;

        .modal-header {
            color: #161616;
            text-align: center;
            @media screen and (max-width: 768px) {
                padding-top: 10px;
            }
            &__title {
                font-size: 24px;
                font-weight: 700;
                margin: 0;
            }
            &__content {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 40px;
                margin-top: 20px;
            }
        }
        .modal-content {
            padding-top: 20px;
            min-height: 150px;
            max-height: 470px;
            padding-bottom: 80px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 768px) {
                padding-top: 10px;
                padding-bottom: 50px;
                min-height: 120px;
                max-height: 340px;
            }
            // START
            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: #e7e7e7;
                border: 1px solid #cacaca;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #887d7d;
            }
            // END
            &__item {
                cursor: pointer;
                margin-right: 10px;
                padding: 10px;
                border: 2px solid #ccc;
                border-radius: 10px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &__64 {
                    order: 6
                }
                &__1 {
                    order: 1
                }
                &__2 {
                    order: 4
                }
                &__8 {
                    order: 5
                }
                &__128 {
                    order: 2
                }
                &__512 {
                    order: 3
                }
            }
            &__item-active {
                border: 2px solid #ff4106;
            }

            &__type {
                color: #808080;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0;
                font-family: "Work Sans";
            }
            &__content {
                font-family: "GRIFTER";
                color: #161616;
                font-size: 20px;
                font-weight: 700;
                margin: 8px 0;
            }
        }
        .modal-footer {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100px;
            background-color: #fff;
            outline: none !important;
            border: "0px solid transparent" !important;
            &__content {
                padding: 30px;
                display: flex;
                justify-content: space-between;
            }
            button {
                width: 48%;
                padding: 0 10px;
            }
            .isLoading {
                cursor: not-allowed;
            }
        }
    }
}
